import dayjs from 'dayjs';

export const parseDate = (date: string) => {
  if (!date) return null;
  return dayjs(date).format('YYYY-MM-DD HH-mm-ss');
};

export const getNumbersListInsideObjects = (min: number, max: number, step: number): Record<string, string>[] => {
  const finalList: Record<string, string>[] = [];
  for (let index = min; index <= max; index += step) {
    finalList.push({
      label: `${index}`,
      value: `${index}`,
    });
  }
  return finalList;
};
