import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueCompositionAPI from '@vue/composition-api';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueToast from 'vue-toast-notification';
import i18nFactory from '@/helpers/i18n/i18nFactory';
import * as Sentry from '@sentry/vue';
import './assets/icons/font-awesome-icons';
import { Userpilot } from 'userpilot';
import './assets/tailwind.css';
import '../node_modules/@zidsa/ui/styles/global.css';
import 'vue-toast-notification/dist/theme-sugar.css';

const i18n = i18nFactory();

Userpilot.initialize(process.env.VUE_APP_USERPILOT_TOKEN);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration({ router })],
  tracePropagationTargets: ['partner.zid.sa'],
  ignoreErrors: [/^(?:https?:\/\/)?(?:[^.]+\.)?clarity\.ms(\/.*)?/],
});

Vue.use(VueToast, {
  position: 'top-right',
  pauseOnHover: true,
  dismissible: true,
  duration: 5000,
});
Vue.component('multiselect', window.VueMultiselect.default);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(VueCompositionAPI);

new Vue({ router, store, i18n, render: (h) => h(App) }).$mount('#app');
